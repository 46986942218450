export const APP_STORE_URL = {
  ANDROID: 'https://play.google.com/store/apps/details?id=mx.org.montepio',
  IOS: 'https://apps.apple.com/mx/app/montep%C3%ADo-luz-savi%C3%B1%C3%B3n/id6503148645',
};

export const PAWNED_CATEGORY = {
  WATCHES: 'Relojes',
  JEWELRY: 'Alhajas',
  CARS: 'Autos',
  DIAMONDS: 'Diamantes',
};

export const RESPONSE_STATUS = {
  SUCCESS: 'success',
  ERROR: 'error',
};

export const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const OTP_LENGTH = window.OTP_LENGTH || 4;

export const PROVIDER_TYPE = {
  BANCOMER: 'BANCOMER',
  PAYCASH: 'PAYCASH',
  PUNTORED: 'PUNTORED',
};

export const CONTRACT_STATUS = {
  COMPLETED: 'COMPLETED',
  PENDING: 'PENDING',
  CANCELED: 'CANCELED',
  PAYMENT_SAP_PENDING: 'PAYMENT_SAP_PENDING',
  FAILED: 'FAILED',
};

export const CONTRACT = 'CONTRACT';
export const SECTION = 'SECTION';

export const PAYCASH_MAX_AMOUNT = 8000;
export const SECTION_MIN_AMOUNT = 20;

export const PHONE_COUNTRY_CODE = '+52';

export const LOCAL_STORAGE_PREFIX = 'mls-';
export const WINDOW_VAR_NOTICES = 'notices';
