import Layout from 'components/Layout';
import useLiterals from 'utils/hooks/useLiterals';
import Lottie from 'react-lottie';
import * as animationData from 'assets/animations/maintenance.json';
import { APP_STORE_URL } from 'constants/global';
import LogoAndroid from 'assets/images/play-store.png';
import LogoApple from 'assets/images/app-store.png';
import Button from 'components/UI/Button';
import './styles.scss';

const UpdateApp = () => {
  const literals = useLiterals('updateApp');

  const openStoreLink = (to) => {
    window.openExternalLink(to);
  };

  return (
    <Layout rightMenu={false} className='text-center'>
      <div className='update-app'>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice',
            },
          }}
          width={275}
        />
        <h1 className='mb-2'>{literals.title}</h1>
        <p className='mt-3 mb-5'>
          {literals.description}
        </p>
        {window.onAndroidApp() && (
          <Button onClick={() => openStoreLink(APP_STORE_URL.ANDROID)} className='mb-4' type='secondary'>
            <img src={LogoAndroid} alt='Platform logo' />
            Play Store
          </Button>
        )}
        {window.onIOSApp() && (
          <Button onClick={() => openStoreLink(APP_STORE_URL.IOS)} type='secondary'>
            <img src={LogoApple} alt='Platform logo' />
            App Store
          </Button>
        )}
      </div>
    </Layout>
  );
};

export default UpdateApp;
