/* eslint-disable class-methods-use-this */
import { useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga4';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Sentry from '@sentry/react';
import { savePushNotificationToken, validateSession } from 'modules/session/actions';
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  matchPath,
} from 'react-router-dom';
import Loading from 'components/Loading';
import ErrorBoundary from 'components/ErrorBoundary';
import Debugger from 'utils/debugger';
import {
  routes, routesMaintenance, routesUpdateApp, routesWeb,
} from 'routes/mainRoutes';
import { ROUTE_PATH, ROUTE_TYPE_REDIRECT } from 'routes';
import SplashScreen from 'components/SplashScreen';
import { getNotificationPermission } from 'utils/events';
import { getGlobalConfiguration } from 'modules/configuration/actions';
import CmsPopups from 'components/CmsPopups';
import { setIsApp, setQueryParams } from 'modules/app/actions';
import { getStores } from 'modules/stores/actions';
import useLocalStorage from 'utils/hooks/useLocalStorage';
import { STORAGE_USER_DATA } from 'constants/local';
import { compareVersion } from 'utils/compareVersion';

const App = (props) => {
  const {
    checkSession,
    setGlobalIsApp,
    configuration,
    user,
    isAuthenticated,
    sessionChecked,
    getConfiguration,
    getStoresAction,
    setQueryParamsAction,
  } = props;

  const navigate = useNavigate();
  const [userData] = useLocalStorage(STORAGE_USER_DATA);

  const [ready, setReady] = useState(false);
  const [forceLogin, setForceLogin] = useState(false);
  const params = useMemo(() => {
    let paramOs = null;
    let paramPreview = false;
    let paramNoSplash = false;
    const queryParams = new URLSearchParams(window.location.search);
    try {
      paramOs = window.localStorage.getItem('os');
      paramPreview = !!(queryParams.get('previewMode') || null);
      paramNoSplash = !!(queryParams.get('noSplashScreen') || null);
      if (!paramOs) {
        paramOs = queryParams.get('os') || null;
        if (paramOs) {
          paramOs = paramOs.toUpperCase();
          window.localStorage.setItem('os', paramOs);
        }
      } else {
        paramOs = paramOs.toUpperCase();
      }
      if (matchPath({ path: ROUTE_PATH.BBVA_REDIRECT }, window.location.pathname)) {
        paramNoSplash = true;
      }
    } catch (error) {
      console.error('error', error);
    }
    paramOs = !!paramOs;
    setGlobalIsApp(paramOs || paramPreview);
    setQueryParamsAction(Object.fromEntries(queryParams || {}));

    return {
      isApp: paramOs,
      isPreviewMode: paramPreview,
      splash: !paramNoSplash,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isOldVersion = useMemo(() => {
    const version = window?.mlsQueryParams?.v || null;
    if (version && configuration?.list?.length) {
      const minVersionAndroid = configuration.list.find((item) => item.name === 'minVersionAndroid')?.value || null;
      const minVersionIOS = configuration.list.find((item) => item.name === 'minVersionIOS')?.value || null;
      const requiredVersion = (window.onIOSApp() && minVersionIOS) || (window.onAndroidApp() && minVersionAndroid) || null;
      return !!(requiredVersion && compareVersion(version, requiredVersion) === -1);
    }
    return false;
  }, [configuration]);

  useEffect(() => {
    let sessionID = true;
    if (window.onApp()) {
      sessionID = window.sessionStorage.getItem('mls-session') || null;
      window.sessionStorage.setItem('mls-session', new Date().getTime());
    }
    checkSession(!sessionID).then((r) => {
      if (r === 'session_destroyed') {
        setForceLogin(true);
      }
    });

    getStoresAction();

    const setVh = () => {
      document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
    };
    setVh();
    window.addEventListener('resize', setVh);
    getNotificationPermission();
    getConfiguration();

    if (!userData?.id) {
      savePushNotificationToken();
    }

    if (window?.mlsQueryParams?.redirect) {
      navigate(window.mlsQueryParams.redirect?.startsWith('/') ? window.mlsQueryParams.redirect : `/${window.mlsQueryParams.redirect}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user?.id) {
      try {
        Sentry.setUser({ id: user.id });
      } catch (error) {
        Debugger.error('Sentry error', error);
      }
    }
    if (ReactGA?.isInitialized) {
      ReactGA.set({ userId: user?.id || undefined });
    }
  }, [user]);

  useEffect(() => {
    if (forceLogin) {
      setForceLogin(false);
      navigate(ROUTE_PATH.LOGIN, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceLogin, sessionChecked]);

  useEffect(() => {
    if (configuration?.list) {
      const gaConfiguration = configuration.list.find((item) => item.name === 'googleAnalytics');
      if (gaConfiguration && gaConfiguration.value && !ReactGA.isInitialized) {
        ReactGA.initialize(gaConfiguration.value);
      }
    }
  }, [configuration]);

  let appRoutes = routesWeb;
  let popups = false;
  if (window.MAINTENANCE) {
    appRoutes = routesMaintenance;
  } else if (isOldVersion) {
    appRoutes = routesUpdateApp;
  } else if (params.isApp || params.isPreviewMode) {
    appRoutes = routes;
    popups = true;
  }
  return (
    <>
      <Loading />
      {params.isApp && params.splash && <SplashScreen onFinish={() => setReady(true)} />}
      {sessionChecked ? (
        <Routes>
          {appRoutes.map((route, index) => {
            if (route.type === ROUTE_TYPE_REDIRECT) {
              return (
                <Route
                  key={index}
                  path={route.from}
                  exact={route.exact}
                  element={<Navigate to={route.to} />}
                />
              );
            }

            return (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                element={
                  (route.ignoreSession || isAuthenticated) ? (
                    <ErrorBoundary user={user}>
                      <div className={`container container-app ${route.className || ''}`}>
                        {(ready || !params.isApp || params.isPreviewMode || !params.splash) && (
                          <route.component />
                        )}
                      </div>
                    </ErrorBoundary>
                  ) : (
                    <Navigate to={ROUTE_PATH.LOGIN} state={{ redirect: window.location.pathname }} replace />
                  )
                }
              />
            );
          })}
        </Routes>
      ) : null}
      {popups && <CmsPopups />}
    </>
  );
};

App.displayName = 'App';
App.propTypes = {
  user: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  sessionChecked: PropTypes.bool.isRequired,
  checkSession: PropTypes.func.isRequired,
  getStoresAction: PropTypes.func.isRequired,
  getConfiguration: PropTypes.func.isRequired,
  setGlobalIsApp: PropTypes.func.isRequired,
  setQueryParamsAction: PropTypes.func.isRequired,
  configuration: PropTypes.object.isRequired,
};

/* *************************************** */
/* ********       CONTAINER       ******** */
/* *************************************** */

function mapStateToProps(state) {
  return {
    isAuthenticated: state.session.authenticated,
    sessionChecked: state.session.checked,
    user: state.session.user,
    configuration: state.configuration,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    checkSession: bindActionCreators(validateSession, dispatch),
    getConfiguration: bindActionCreators(getGlobalConfiguration, dispatch),
    setGlobalIsApp: bindActionCreators(setIsApp, dispatch),
    getStoresAction: bindActionCreators(getStores, dispatch),
    setQueryParamsAction: bindActionCreators(setQueryParams, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
